import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/tsuto/webapp/pcrecommend/src/components/layout.js";
import Image from "components/image";
import TOC from "components/toc";
import SaleLoadable from "components/saleLoadable";
import Socials from "components/socials";
import AccentBox from "components/accentBox";
import BasicTable from "components/basicTable";
import Card from "components/card";
import { Link, graphql } from "gatsby";
import Twenty from "react-twentytwenty";
import List from "components/list";
import Youtube from "components/youtube";
export const query = graphql`
  {
    allMdx(filter: {slug: {eq: "gamingpc"}}) {
      nodes {
        headings {
          depth
          value
        }
      }
    },
    cover: file(relativePath: { eq: "cover/pcselection/gamingpc.png"}) { ...eyecatchImg },
    fullHD: file(relativePath: { eq: "monitor/fullHD.png"}) { ...normalImg },
    rtxoff: file(relativePath: { eq: "gpu/rtxoff.png"}) { ...normalImg },
    rtxon: file(relativePath: { eq: "gpu/rtxon.png"}) { ...normalImg },
    rtxoff2: file(relativePath: { eq: "gpu/rtxoff2.png"}) { ...normalImg },
    rtxon2: file(relativePath: { eq: "gpu/rtxon2.png"}) { ...normalImg },
    gamespec: file(relativePath: { eq: "gaming/gamespec.png"}) { ...normalImg },
    cgrabohikaku: file(relativePath: { eq: "cover/gpu/grabo-hikaku.png"}) { ...eyecatchImg },
    oversixty: file(relativePath: { eq: "gaming/oversixty.PNG"}) { ...normalImg },
    ccpubottleneck: file(relativePath: { eq: "cover/gpu/cpubottleneck.png"}) { ...eyecatchImg },
    crtx3060: file(relativePath: { eq: "cover/gpu/rtx3060.png"}) { ...eyecatchImg },
    nvidia_amd: file(relativePath: { eq: "gaming/nvidia_amd.png"}) { ...normalImg },
    ccpuhikaku: file(relativePath: { eq: "cover/cpu/cpu-hikaku.png"}) { ...eyecatchImg },
    cnvmeahcissd: file(relativePath: { eq: "cover/nvme-ahci-ssd.jpg"}) { ...eyecatchImg },
    csshd: file(relativePath: { eq: "cover/sshd.png"}) { ...eyecatchImg },
    ckeyboard: file(relativePath: { eq: "cover/pcparts/keyboard.png"}) { ...eyecatchImg },
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`レイトレ時代に変わるゲーミングPCの選び方とおすすめの機種`}</h1>
    <Socials {...props} mdxType="Socials" />
    <Image {...props} name="cover" alt="ゲーミングPCの選び方" mdxType="Image" />
    <p>{`レイトレーシング、DLSS、NVMe SSDなどゲーミングPCは先端のソフトウェア技術やマテリアルから構成され、特にゲーミングPC初心者にとっては、これらの構成がどのようなゲーム体験に繋がるのかを考慮することは難しい。`}</p>
    <p>{`このため、注意して選ばないとグレードの低いGPUを搭載したために先端の映像体験を知らぬままに終わる、あるいは高額なゲーミングPCを購入したが宝の持ち腐れとなる可能性が高い。`}</p>
    <p>{`そこで本サイトでは、ゲーミングPCの構成を考える上で必要な用語やトレンドを踏まえた上で、コストを抑えつつも最先端が体験できるスペック及び、おすすめのパソコンを紹介する。`}</p>
    <SaleLoadable title="現在のパソコンセール・お買い得情報" mdxType="SaleLoadable" /><br />
    <TOC {...props} mdxType="TOC" />
    <h2 {...{
      "id": "フレームレート、解像度、レイトレーシングの用語解説",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%83%95%E3%83%AC%E3%83%BC%E3%83%A0%E3%83%AC%E3%83%BC%E3%83%88%E3%80%81%E8%A7%A3%E5%83%8F%E5%BA%A6%E3%80%81%E3%83%AC%E3%82%A4%E3%83%88%E3%83%AC%E3%83%BC%E3%82%B7%E3%83%B3%E3%82%B0%E3%81%AE%E7%94%A8%E8%AA%9E%E8%A7%A3%E8%AA%AC",
        "aria-label": "フレームレート、解像度、レイトレーシングの用語解説 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`フレームレート、解像度、レイトレーシングの用語解説`}</h2>
    <p>{`ゲーミングPCを選ぶにあたって、この３つの用語と感覚を掴まない限りは正しいパソコンの購入はできない。高価なゲーミングPCほど美しい映像を滑らかな動きで実現することができるが、その鍵を握るものがフレームレート、解像度、レイトレーシングである。`}</p>
    <p>{`まずはこれらの用語を解説するので感覚と共に頭に叩きこもう。逆に言葉を知っている人はこの章は読み飛ばして欲しい。`}</p>
    <h3 {...{
      "id": "フレームレートFPS",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%83%95%E3%83%AC%E3%83%BC%E3%83%A0%E3%83%AC%E3%83%BC%E3%83%88FPS",
        "aria-label": "フレームレートFPS permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`フレームレート(FPS)`}</h3>
    <p>{`映像は静止画を短時間のうちに何枚も描画することによって、人間に動きがあるような錯覚を作り出している。当然時間あたりに描画する静止画である「コマ」の枚数が多いほど滑らかな映像を実現できる。これを基準化したものがフレームレートである。`}</p>
    <p><strong parentName="p"><em parentName="strong">{`フレームレートはFPS(Frame Per Second)とも呼ばれ、１秒間に描画される静止画の枚数を表す`}</em></strong>{`。アニメならば１秒間に24枚の絵を映しているので24FPSであり、テレビゲームならば60FPSが普通である。`}</p>
    <video autoPlay muted playsInline loop style={{
      width: '100%'
    }}>
  <source src="/video/framerate.mp4" type="video/mp4" />
    </video>
    <p>{`上の繰り返し動画を見ると分かると思うが、ゲームの映像で60FPS出ていれば十分に滑らかだとされ、一般的なモニタやテレビも60FPS以上の映像は映し出すことはできない。`}</p>
    <p>{`つまり`}<strong parentName="p"><em parentName="strong">{`60FPSをゲームで出すことができるのかがゲーミングPC選びで重要な基準の一つ`}</em></strong>{`となってくる。`}</p>
    <h3 {...{
      "id": "解像度FHDQHD4K",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E8%A7%A3%E5%83%8F%E5%BA%A6FHDQHD4K",
        "aria-label": "解像度FHDQHD4K permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`解像度(FHD/QHD/4K)`}</h3>
    <p>{`フレームレートは映像の滑らかさの基準であるが、映像の繊細さの基準が解像度である。4Kテレビなどで知っている人も多いかもしれない。`}</p>
    <p><strong parentName="p"><em parentName="strong">{`解像度とは画面を表示するためのドット（画像を表示するための細かな点)が横、縦それぞれで何個あるかを示す値`}</em></strong>{`である。このドットが集まって画面は構成されており、
FHDだと横1920ドット、縦1080ドットである。`}</p>
    <Image {...props} name="fullHD" alt="FHD液晶モニター" mdxType="Image" />
    <p>{`グラフィックボードは各ドットに対して映像の出力命令をおこなう。モニターが対応する以上の解像度は出せないが、それ以下の場合だと映像は引き延ばされて表示されることになる。`}</p>
    <p>{`例えば、4K解像度のモニターに対して、グラフィックボードがFHDの出力を行った場合にはモニター側が自動的に縦と横のドットを２倍に引き延ばして表示する。`}</p>
    <p>{`ゲームをおこなう上での代表的な解像度は以下である。`}</p>
    <BasicTable className="center" mdxType="BasicTable">
  <thead>
    <tr>
      <th>規格名</th>
      <th>横ドット数</th>
      <th>縦ドット数</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>FHD</td>
      <td>1920</td>
      <td>1080</td>
    </tr>
    <tr>
      <td>QHD</td>
      <td>2560</td>
      <td>1440</td>
    </tr>
    <tr>
      <td>4K</td>
      <td>3840</td>
      <td>2160</td>
    </tr>
  </tbody>
    </BasicTable>
    <p>{`解像度が高いほど、描画の能力が要求され、`}<strong parentName="p"><em parentName="strong">{`ゲームを行う上でのベースはFHDであるが、高価なグラフィックボードを搭載することでその上を実現できる`}</em></strong>{`。`}</p>
    <h3 {...{
      "id": "レイトレーシング",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%83%AC%E3%82%A4%E3%83%88%E3%83%AC%E3%83%BC%E3%82%B7%E3%83%B3%E3%82%B0",
        "aria-label": "レイトレーシング permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`レイトレーシング`}</h3>
    <p>{`レイトレーシングは光(Ray)の経路を追跡(Tracing)して、最終的にモニタに映し出される画素を決定するレンダリングの技術であり、`}<strong parentName="p"><em parentName="strong">{`光の描写を現実世界に近づけ、より実写に近い映像を作り出すことができる`}</em></strong>{`。`}</p>
    <p>{`百聞は一見に如かずなので、次のマインクラフトの絵を比べてみて欲しい。(マウスやタップでぐりぐり可能)`}</p>
    <Twenty left={<Image {...props} name="rtxoff" mdxType="Image" />} right={<Image {...props} name="rtxon" mdxType="Image" />} mdxType="Twenty" />
    <p>{`次はCyberPunk 2077の例`}</p>
    <Twenty left={<Image {...props} name="rtxoff2" mdxType="Image" />} right={<Image {...props} name="rtxon2" mdxType="Image" />} mdxType="Twenty" />
    <p>{`より自然な光の描画が行われていることが分かるだろう。`}</p>
    <p>{`光源から来た光が物体に反射して、新たな光源となり、その光がまた反射して、、、という膨大な計算を行うための従来から技術はあったものの、リアルタイムで演算を行うことは難しく、その用途は映画などの動画に限られた。`}</p>
    <p>{`これがGPU能力の向上により可能になりリアルタイムレイトレーシングに対応したグラフィックボードが発売されるに至っている。`}</p>
    <p><strong parentName="p"><em parentName="strong">{`NVIDIAのRTXシリーズが、リアルタイムレイトレーシングに対応した代表的なGPU`}</em></strong>{`である。`}</p>
    <h2 {...{
      "id": "ゲーミングPCはFHD60FPSをボトムにプラスαを考える",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%82%B2%E3%83%BC%E3%83%9F%E3%83%B3%E3%82%B0PC%E3%81%AFFHD60FPS%E3%82%92%E3%83%9C%E3%83%88%E3%83%A0%E3%81%AB%E3%83%97%E3%83%A9%E3%82%B9%CE%B1%E3%82%92%E8%80%83%E3%81%88%E3%82%8B",
        "aria-label": "ゲーミングPCはFHD60FPSをボトムにプラスαを考える permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ゲーミングPCはFHD+60FPSをボトムにプラスαを考える`}</h2>
    <Image {...props} name="gamespec" alt="ゲーミングPCはFHD+60FPSがボトム" mdxType="Image" />
    <p>{`ゲームを快適に行う上での前提は、FHDで60FPSを出せることである。これを下回ると映像は鮮明ではなく、カクカクと動いて見える。`}</p>
    <p>{`FHD+60FPSを達成するためのゲーミングPCは10万円を切る価格で購入が可能であるが、`}<strong parentName="p"><em parentName="strong">{`問題は付加価値である4K、QHD解像度、レイトレーシング、高FPSに如何に投資していくか`}</em></strong>{`ということである。`}</p>
    <p>{`これは行いたいゲームや、自分のPC環境によっても異なってくる。本節ではまずFHD+60FPSを達成するにはどのGPUを搭載すべきかを述べ、次に選ぶべき付加価値について解説する。`}</p>
    <h3 {...{
      "id": "GPU比較表でFHD60FPSを出せるGPUをチェックしよう",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#GPU%E6%AF%94%E8%BC%83%E8%A1%A8%E3%81%A7FHD60FPS%E3%82%92%E5%87%BA%E3%81%9B%E3%82%8BGPU%E3%82%92%E3%83%81%E3%82%A7%E3%83%83%E3%82%AF%E3%81%97%E3%82%88%E3%81%86",
        "aria-label": "GPU比較表でFHD60FPSを出せるGPUをチェックしよう permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`GPU比較表でFHD+60FPSを出せるGPUをチェックしよう`}</h3>
    <p>{`まずは当サイトで準備しているGPU性能比較表を確認しよう。ゲームの性能の大半はGPU(グラフィックボード)で決まり、`}<strong parentName="p"><em parentName="strong">{`ある程度重めのゲームをした時に出せるFPSをリンク先の表では提示している`}</em></strong>{`。`}</p>
    <Card {...props} title="グラボ(GPU)性能比較表" description="グラフィックボードの比較を簡単に。ゲームに必要な性能をFHD、QHD、4Kの参考フレームレート、3DMark、コスパ、TDP等で比較します。" image="cgrabohikaku" path="/gpu/" mdxType="Card" />
    <p>{`代表的なGPUだと、`}<strong parentName="p"><em parentName="strong">{`NVIDIAのGeforce 1060 6GBだとFHDで60FPSが達成できる事が分かる`}</em></strong>{`。ある程度快適にゲームを行いたい場合には、このあたりのGPUを探せば良いことになる。`}</p>
    <h3 {...{
      "id": "動きの速いゲームはフレームレートが重要",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E5%8B%95%E3%81%8D%E3%81%AE%E9%80%9F%E3%81%84%E3%82%B2%E3%83%BC%E3%83%A0%E3%81%AF%E3%83%95%E3%83%AC%E3%83%BC%E3%83%A0%E3%83%AC%E3%83%BC%E3%83%88%E3%81%8C%E9%87%8D%E8%A6%81",
        "aria-label": "動きの速いゲームはフレームレートが重要 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`動きの速いゲームはフレームレートが重要`}</h3>
    <p>{`付加価値の一つ目フレームレートであるが、テレビ向けゲームの基本が60FPSであるように、60FPSあれば十分に滑らかである。実際にRPGや格闘ゲームなどではこれ以上のFPSがあってもその恩恵を受けるメリットは少ないだろう。`}</p>
    <p>{`しかし、`}<em parentName="p">{`APEX`}</em>{`や`}<em parentName="p">{`コールオブデューティ－`}</em>{`、`}<em parentName="p">{`フォートナイト`}</em>{`といった動きの速いアクションシューティングゲームやレーシングゲームを行う場合には、映像が目まぐるしく変わるため、より滑らかな映像だと残像(ゴーストとも)の発生が少なく快適になる。`}</p>
    <p>{`どれぐらいまでだと`}<strong parentName="p"><em parentName="strong">{`体感的に違いが分かるかという点で言えば144FPSを一つの目安と考えればよい`}</em></strong>{`。4倍速テレビでは240FPSまで実現できているが、120FPSと240FPSは横スクロール画像をじっくり比較してようやく分かる程度なので費用対効果は高くない。`}</p>
    <p>{`ちなみに`}<em parentName="p">{`人間の目で認識できる速度の限界は240FPS程度`}</em>{`とされるので360FPSとか謳っているモニターは、数値を追い求める趣味の領域に入っていると言える。`}</p>
    <p>{`次に、高FPSを目指す上で、グラフィックボード以外に注意点が２つある。`}</p>
    <p>{`１つ目は`}<strong parentName="p"><em parentName="strong">{`専用のゲーミングモニターが必要`}</em></strong>{`ということである。通常のモニターでは60FPSまでしか対応していないためこの投資は必須となる。ゲーミングモニターは高FPSに対応する以外にも、描画中の画像のズレを抑える`}<a parentName="p" {...{
        "href": "https://amzn.to/3tZnMj7",
        "target": "_blank",
        "rel": "nofollow noopener"
      }}>{`G-Sync対応のモニター`}</a>{`を購入しよう。`}</p>
    <p>{`より細かくは`}<strong parentName="p"><em parentName="strong">{`NVIDIA製グラボだとG-Sync、AMD製グラボだとFreeSyncに対応`}</em></strong>{`である。`}</p>
    <Image {...props} name="oversixty" alt="I-O DATA ゲーミングモニター" link="https://amzn.to/3r4jViu" linkText="By amazon: 144FPSゲーミングモニター" mdxType="Image" />
    <p>{`２つ目は`}<strong parentName="p"><em parentName="strong">{`CPUがボトルネックとなってFPSが伸びない可能性を考慮して、気持ち高めのCPUを搭載する必要がある`}</em></strong>{`ことである。1秒間に何度も描画するということは、たとえば当たり判定も描画ごとに行われることになり、CPUのリソースが圧迫されるわけである。`}</p>
    <p>{`一方で解像度やレイトレーシングを重視する場合には、フレームレートが抑えられCPUの演算時間に余裕ができるため、それほどCPUのことは気にする必要はない。`}</p>
    <p>{`気持ち高めと書いて、曖昧だと思われるかもしれないが、CPUがボトルネックとなる条件はゲームとその描画設定によりけりで難しい。気になる人は下記の記事も参考にして欲しい。`}</p>
    <Card {...props} title="CPUボトルネックを回避する" description="ゲームにおけるCPUの役割、4Kゲームとボトルネック、GPUとの組み合わせ等を解説" image="ccpubottleneck" path="/gpu/cpu-bottleneck/" mdxType="Card" />
    <h3 {...{
      "id": "モニターサイズ27インチ以上でQHD、40インチ以上で4Kの解像度はあり",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%83%A2%E3%83%8B%E3%82%BF%E3%83%BC%E3%82%B5%E3%82%A4%E3%82%BA27%E3%82%A4%E3%83%B3%E3%83%81%E4%BB%A5%E4%B8%8A%E3%81%A7QHD%E3%80%8140%E3%82%A4%E3%83%B3%E3%83%81%E4%BB%A5%E4%B8%8A%E3%81%A74K%E3%81%AE%E8%A7%A3%E5%83%8F%E5%BA%A6%E3%81%AF%E3%81%82%E3%82%8A",
        "aria-label": "モニターサイズ27インチ以上でQHD、40インチ以上で4Kの解像度はあり permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`モニターサイズ27インチ以上でQHD、40インチ以上で4Kの解像度はあり`}</h3>
    <p>{`次に解像度の話。ここはサクッと行きたい。`}</p>
    <p>{`23インチ4Kテレビが発売されていないように、ドットがいくら細かくても、判断がつかないならば映像美に関する体感は向上しない。`}<strong parentName="p"><em parentName="strong">{`QHDで画質向上が期待できるモニタサイズは27インチ以上、4Kでは40インチ以上が妥当な水準`}</em></strong>{`である。`}</p>
    <p>{`40インチのモニタでパソコン作業はやりにくいので、27インチでQHDが迫力のある映像をゲームで楽しみたい場合はおすすめではないかと個人的には思う。`}</p>
    <h3 {...{
      "id": "レイトレーシングはオープンワールドであると最適",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%83%AC%E3%82%A4%E3%83%88%E3%83%AC%E3%83%BC%E3%82%B7%E3%83%B3%E3%82%B0%E3%81%AF%E3%82%AA%E3%83%BC%E3%83%97%E3%83%B3%E3%83%AF%E3%83%BC%E3%83%AB%E3%83%89%E3%81%A7%E3%81%82%E3%82%8B%E3%81%A8%E6%9C%80%E9%81%A9",
        "aria-label": "レイトレーシングはオープンワールドであると最適 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`レイトレーシングはオープンワールドであると最適`}</h3>
    <p>{`FHDから4Kよりもレイトレーシングに対応する方が、リアルな映像を楽しみたい場合は効果が大きいと感じる。しかしながらあらゆる光の反射を想定するレイトレーシングは計算量が莫大でありFPSはかなり犠牲にせざるを得ない。`}</p>
    <p>{`よって動きの速いアクションシューティング系のゲームよりは、`}<strong parentName="p"><em parentName="strong">{`街並みを堪能できるオープンワールドのあるゲームでより効果的`}</em></strong>{`である。ゲーム名で言うと`}<em parentName="p">{`サイバーパンク`}</em>{`や`}<em parentName="p">{`マインクラフト`}</em>{`などである。`}</p>
    <p>{`レイトレーシングに対応していないゲームもまだ多いが、今後続々と対応することが期待されるので、ゲーミングパソコンを購入するならば注目しておきたい。`}</p>
    <p>{`レイトレーシングを快適に行うにはNVIDIAのRTXシリーズが搭載されたパソコンを購入するのが良い。RTXシリーズはRTXコアと呼ばれるレイトレーシング計算専用のプロセッサを持っており莫大な計算を高速に行うことができる。`}</p>
    <p><strong parentName="p"><em parentName="strong">{`FHD+60FPSで確実に遊ぶには`}<a parentName="em" {...{
            "href": "https://amzn.to/39oYMbO",
            "target": "_blank",
            "rel": "nofollow noopener"
          }}>{`RTX 3060`}</a>{`以上をおすすめしたい`}</em></strong>{`。`}</p>
    <Card {...props} title="RTX 3060はレイトレーシング時代のニュースタンダードGPU" description="RTX3060が変えるゲーム体験をRTXやDLSSの技術、GTX1060、RTX2060との違いもふまえ解説" image="crtx3060" path="/gpu/rtx3060/" mdxType="Card" />
    <h2 {...{
      "id": "グラフィックボードは何を選択すべきか？",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%82%B0%E3%83%A9%E3%83%95%E3%82%A3%E3%83%83%E3%82%AF%E3%83%9C%E3%83%BC%E3%83%89%E3%81%AF%E4%BD%95%E3%82%92%E9%81%B8%E6%8A%9E%E3%81%99%E3%81%B9%E3%81%8D%E3%81%8B%EF%BC%9F",
        "aria-label": "グラフィックボードは何を選択すべきか？ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`グラフィックボードは何を選択すべきか？`}</h2>
    <Image {...props} name="nvidia_amd" alt="NVIDIAとAMD" mdxType="Image" />
    <p>{`パソコン用にグラフィックボードを製造している会社はNVIDIA(エヌビディア)とAMDの２社があり、`}<strong parentName="p"><em parentName="strong">{`NVIDIAがGeforce、AMDがRadeon`}</em></strong>{`(ラデオンと呼ぶ人が多い、英語発音ではレイディオン)というブランド名で販売している。シェアはNVIDIAの方が高い。`}</p>
    <p>{`より細かく言うと、これら２社はグラフィックボードのコア部分を作成しており、冷却ファン等と組み合わせて最終製品としては`}<em parentName="p">{`玄人志向`}</em>{`や`}<em parentName="p">{`MSI`}</em>{`といった会社が製造している。`}</p>
    <p>{`基本的には先の`}<Link to="/gpu/" mdxType="Link">{`GPU性能比較表`}</Link>{`を頼りにGPUを判断していけば良いが、レイトレーシングの能力はNVIDIAのRTXシリーズが上なので、`}<strong parentName="p"><em parentName="strong">{`レイトレを考えるならば現在はNVIDIA一択`}</em></strong>{`である。`}</p>
    <p>{`NVIDIAのRTXシリーズは前述したRTコアの他に、TensorコアというAI専用プロセッサを持ち、`}<em parentName="p">{`DLSS(Deep Learning Super Sampling)`}</em>{`というAI用いた画素補完技術でフレームレートを大幅に引き上げることに成功している。`}</p>
    <h2 {...{
      "id": "CPUはPassMarkが強いものを選ぶ",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#CPU%E3%81%AFPassMark%E3%81%8C%E5%BC%B7%E3%81%84%E3%82%82%E3%81%AE%E3%82%92%E9%81%B8%E3%81%B6",
        "aria-label": "CPUはPassMarkが強いものを選ぶ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`CPUはPassMarkが強いものを選ぶ`}</h2>
    <p>{`CPUはグラフィックボードの次に重要度が高いパーツである。ゲームにおいて、描画処理はGPUに任せるが、その他の多くはCPUが担当する。`}</p>
    <p>{`たとえば、シューティングゲームなどで弾が敵に当たったかを判定する当たり判定は、描画処理が走る度にCPUが物理演算をしなければならない。`}</p>
    <p>{`これらの処理は並列演算が苦手なものが多く、シングルスレッド性能がFPSに与える影響が大きい。このため`}<em parentName="p">{`PassMarkのシングルスレッドの値`}</em>{`も合わせて確認して、バランスの良いCPUを探せば良い。下記のリンク先から参考にしてほしい。`}</p>
    <Card {...props} title="CPU性能比較表" description="400を超えるCPUから高機能フィルタにより世代間/ベンチマーク/TDP/コスパ等の絞りこみが行えます。" image="ccpuhikaku" path="/cpu/" mdxType="Card" />
    <p>{`尚、`}<strong parentName="p"><em parentName="strong">{`4Kやレイトレーシングを重視して、FPSをさほど重視しなくても良い場合はデスクトップ版Core i5やRyzen 5などのコスパの良いモデルを選べば良い`}</em></strong>{`だろう。`}</p>
    <h2 {...{
      "id": "メモリは16GB積んでおけば安全",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%83%A1%E3%83%A2%E3%83%AA%E3%81%AF16GB%E7%A9%8D%E3%82%93%E3%81%A7%E3%81%8A%E3%81%91%E3%81%B0%E5%AE%89%E5%85%A8",
        "aria-label": "メモリは16GB積んでおけば安全 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`メモリは16GB積んでおけば安全`}</h2>
    <p>{`GPUの描画処理にはメモリを多く消費するが、`}<strong parentName="p">{`GPU自身が抱えているメモリが消費されるため、メモリースロットに挿すメモリ量は大して大きくはならない`}</strong>{`。`}</p>
    <p>{`もちろん描画処理以外の処理では通常のメモリを使用するため、ある程度は必要だが、`}<strong parentName="p">{`基本的に8GBあれば大丈夫な場合が多い`}</strong>{`。`}</p>
    <p>{`しかし、8GBでの問題点として、ブラウザを閲覧しながらゲームをプレイするといったマルチなユースケースで若干動作がもたつく可能性はある。また、動作要件として16GB近いメモリを要求するゲームもあるにはあるため、`}<strong parentName="p"><em parentName="strong">{`16GBを積んでおけば安全`}</em></strong>{`である。`}</p>
    <p>{`必要になった段階で8GBのメモリを１万円程度で買い増すのも良い。メモリーの増設はケースを開けてメモリスロットに挿すだけなので簡単である。`}</p>
    <h2 {...{
      "id": "NVMe-SSDがロード時間短縮のために効力を発揮",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#NVMe-SSD%E3%81%8C%E3%83%AD%E3%83%BC%E3%83%89%E6%99%82%E9%96%93%E7%9F%AD%E7%B8%AE%E3%81%AE%E3%81%9F%E3%82%81%E3%81%AB%E5%8A%B9%E5%8A%9B%E3%82%92%E7%99%BA%E6%8F%AE",
        "aria-label": "NVMe SSDがロード時間短縮のために効力を発揮 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`NVMe SSDがロード時間短縮のために効力を発揮`}</h2>
    <p>{`ロード時間の大きなファクターを占める要素がストレージである。種類として大きく分けると`}<strong parentName="p">{`高速な順にNVMe SSD、SATA SSD、SSHD、HDDの４種類`}</strong>{`がある。`}</p>
    <p>{`NVMe SSDはPCI Express接続によりSATA SSDより高速な通信を実現する規格で、SSHDはSSDをキャッシュとして使うことでSSDとHDDの中間的役割を持たせたHDDであるが、気になる人は下記リンクを参照して欲しい。`}</p>
    <Card {...props} title="NVMe、AHCI、M.2など良く分からない人へSSDの選び方" description="NVMeとSATA SSDの違い等を解説。" image="cnvmeahcissd" path="/storage/ssd_nvme_ahci_m2_pcie_sata/" mdxType="Card" />
    <Card {...props} title="SSHDとは何か?" description="SSHDとは？SSDをキャッシュとして使うHDD?実際のパフォーマンスは？コスパはどうか？など説明。" image="csshd" path="/storage/sshd/" mdxType="Card" />
    <h3 {...{
      "id": "ストレージの種類別ロード時間",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%82%B9%E3%83%88%E3%83%AC%E3%83%BC%E3%82%B8%E3%81%AE%E7%A8%AE%E9%A1%9E%E5%88%A5%E3%83%AD%E3%83%BC%E3%83%89%E6%99%82%E9%96%93",
        "aria-label": "ストレージの種類別ロード時間 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ストレージの種類別ロード時間`}</h3>
    <p>{`ではそれぞれのストレージでどの程度のロード時間が掛かるのか？それを複数のゲームの任意のシーンで比較した動画があるため挙げておく。`}</p>
    <Youtube mdxType="Youtube">
  <iframe width="560" height="315" src="https://www.youtube.com/embed/2AYextvB9l4" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    </Youtube>
    <p>{`動画の内容を表にまとめると次のようになる。`}</p>
    <BasicTable mdxType="BasicTable">
  <thead>
    <tr>
      <th>ゲームタイトル</th>
      <th>NVMe SSD</th>
      <th>SATA SSD</th>
      <th>SSHD</th>
      <th>HDD</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>Shadow of the Tomb Raider</td>
      <td>12.46秒</td>
      <td>14.34秒</td>
      <td>31.46秒</td>
      <td>35.13秒</td>
    </tr>
    <tr>
      <td>Far Cry 5</td>
      <td>9.31秒</td>
      <td>10.20秒</td>
      <td>24.42秒</td>
      <td>28.52秒</td>
    </tr>
    <tr>
      <td>Assassin's Creed Odyssey</td>
      <td>27.15秒</td>
      <td>29.10秒</td>
      <td>50.39秒</td>
      <td>52.46秒</td>
    </tr>
    <tr>
      <td>The Witcher 3</td>
      <td>5.34秒</td>
      <td>5.34秒</td>
      <td>22.25秒</td>
      <td>22.34秒</td>
    </tr>
    <tr>
      <td>Kingdom Come Deliverance</td>
      <td>5.18秒</td>
      <td>5.35秒</td>
      <td>6.27秒</td>
      <td>16.18秒</td>
    </tr>
    <tr>
      <td>Battlefield 5</td>
      <td>69.25秒</td>
      <td>80.29秒</td>
      <td>83.09秒</td>
      <td>93.33秒</td>
    </tr>
    <tr>
      <td>Ghost Recon Wildlands</td>
      <td>11.16秒</td>
      <td>12.13秒</td>
      <td>22.05秒</td>
      <td>28.44秒</td>
    </tr>
    <tr>
      <td>GTA 5</td>
      <td>30.34秒</td>
      <td>41.58秒</td>
      <td>51.38秒</td>
      <td>56.47秒</td>
    </tr>
    <tr>
      <td>Resident Evil 2 Remake</td>
      <td>2.37秒</td>
      <td>2.51秒</td>
      <td>2.51秒</td>
      <td>3.06秒</td>
    </tr>
  </tbody>
    </BasicTable>
    <p>{`各ゲームにより大きなバラつきがあるものの、見て分かることは、NVMe SSDとSATA SSDの差はそれほど大きくはないが、SATA SSDとSSHD及びHDDとの開きは大きいという事である。`}</p>
    <p>{`この点から`}<strong parentName="p"><em parentName="strong">{`コストパフォーマンスを重視するならばSATA SSDが、コストをかけれるならばNVMe SSDがおすすめ`}</em></strong>{`である。`}</p>
    <p>{`一方でHDDは値段が安くともゲームをする上でおすすめはできない。SSDとHDDの両方をパソコンに搭載するケースも多いが、ゲームをインストールする場所は必ずSSDにしよう。`}</p>
    <p>{`またゲーム１本で50GB程度あるものも多いので256GBのSSD容量だと物足りなさを感じる。`}<strong parentName="p"><em parentName="strong">{`最低でも512GBはあった方が良い`}</em></strong>{`だろう。`}</p>
    <h2 {...{
      "id": "ノートよりデスクトップパソコンがおすすめ",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%83%8E%E3%83%BC%E3%83%88%E3%82%88%E3%82%8A%E3%83%87%E3%82%B9%E3%82%AF%E3%83%88%E3%83%83%E3%83%97%E3%83%91%E3%82%BD%E3%82%B3%E3%83%B3%E3%81%8C%E3%81%8A%E3%81%99%E3%81%99%E3%82%81",
        "aria-label": "ノートよりデスクトップパソコンがおすすめ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ノートよりデスクトップパソコンがおすすめ`}</h2>
    <p>{`ノートパソコンは好きな場所でできるというメリットは大きいが、ゲームを快適に行う上ではおすすめのできない点が複数あるのでいくつか指摘する。`}</p>
    <h3 {...{
      "id": "コストパフォーマンスが悪い",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%82%B3%E3%82%B9%E3%83%88%E3%83%91%E3%83%95%E3%82%A9%E3%83%BC%E3%83%9E%E3%83%B3%E3%82%B9%E3%81%8C%E6%82%AA%E3%81%84",
        "aria-label": "コストパフォーマンスが悪い permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`コストパフォーマンスが悪い`}</h3>
    <p>{`デスクトップ版とノート版のCPU、グラフィックボードの性能を比較すると、デスクトップ版の方がコスト当たりのパフォーマンスが高い。`}</p>
    <p>{`理由は明確で、ノートパソコンでは熱を抑える必要があるためCPU、グラフィックボードともに消費電力が低いモデルが使われるためである。`}</p>
    <p>{`この結果、`}<strong parentName="p"><em parentName="strong">{`デスクトップでは10万円程度で組めるスペックが、ノートになると倍の20万円近くかかるのが普通`}</em></strong>{`である。`}</p>
    <h3 {...{
      "id": "オリジナルのキーボードが使えない",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%82%AA%E3%83%AA%E3%82%B8%E3%83%8A%E3%83%AB%E3%81%AE%E3%82%AD%E3%83%BC%E3%83%9C%E3%83%BC%E3%83%89%E3%81%8C%E4%BD%BF%E3%81%88%E3%81%AA%E3%81%84",
        "aria-label": "オリジナルのキーボードが使えない permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`オリジナルのキーボードが使えない`}</h3>
    <p>{`より快適にゲームを楽しむためには自分に合った道具を揃えていく必要がある。ところがノートだと好きなキーボードに変更することができない。また画面とキーボードの位置関係も固定されるため、姿勢の制限が加わることも快適さを損なう原因となる。`}</p>
    <p>{`デスクトップならば、キーボードが気に入らなかった場合や酷使して故障した場合にも手軽に変更できるため融通が効く。`}</p>
    <h3 {...{
      "id": "VRはノートパソコンが便利な面がある",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#VR%E3%81%AF%E3%83%8E%E3%83%BC%E3%83%88%E3%83%91%E3%82%BD%E3%82%B3%E3%83%B3%E3%81%8C%E4%BE%BF%E5%88%A9%E3%81%AA%E9%9D%A2%E3%81%8C%E3%81%82%E3%82%8B",
        "aria-label": "VRはノートパソコンが便利な面がある permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`VRはノートパソコンが便利な面がある`}</h3>
    <p>{`最後にノートパソコンの良い点を述べる。様々な問題があるとはいえ、好きな場所でできることのメリットは大きい。よって上記のデメリットを意識した上で最終的には購入を決める必要がある。`}</p>
    <p>{`またVRを行う場合、VRゴーグルとパソコンをHDMIなどのケーブルで繋ぐ必要があるため、デスクトップの場合はコードの長さが制限となり十分に可動域を確保できない可能性がある。`}</p>
    <p>{`たとえばVRで自分が動くゲームを行おうとすると、幅2m、奥行き1.5mほどのスペースを確保する必要がある。しかし机の左下などにパソコン本体をおいているような場合にはコードの長さが足りなくなる。`}</p>
    <p>{`一方でノートパソコンの場合は好きな場所にパソコンをおけるため、可動域を確保しやすく、また家の中の広いスペースを選び遊ぶことも可能である。`}</p>
    <h2 {...{
      "id": "キーボードはゲーミング専用のものを",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%82%AD%E3%83%BC%E3%83%9C%E3%83%BC%E3%83%89%E3%81%AF%E3%82%B2%E3%83%BC%E3%83%9F%E3%83%B3%E3%82%B0%E5%B0%82%E7%94%A8%E3%81%AE%E3%82%82%E3%81%AE%E3%82%92",
        "aria-label": "キーボードはゲーミング専用のものを permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`キーボードはゲーミング専用のものを`}</h2>
    <p>{`パソコン購入時に付属するような`}<strong parentName="p"><em parentName="strong">{`安いキーボードはボタンの同時押しが２つまでしかできない`}</em></strong>{`。具体的にはキーボード内部の配線方法によるのだが、３つ以上の同時押しができない組み合わせが存在する。`}</p>
    <p>{`このため例えば２つのボタンを押して斜め方向に移動しながらジャンプをしたり武器を振るうという基本の動作が、キー配列によってはできない事がある。`}</p>
    <p>{`専用のゲーミングキーボードは高価であるが、ボタンを複数同時押しをしても認識するようになっている。これを`}<strong parentName="p"><em parentName="strong">{`Nキーロールオーバー機能`}</em></strong>{`と呼び、10個同時押しが可能ならば10キーロールオーバー、26個同時押しならば26キーロールオーバーと呼ばれる。`}</p>
    <p>{`基本的に４キーロールオーバー以上が保証されていれば快適にどんなゲームであっても遊べると思われる。因みに`}<strong parentName="p">{`ゲーマー向けにはメカニカルスイッチの青軸という種類が人気`}</strong>{`であったが、音声チャットをしながらチームプレイとなると打鍵音が気になるという声もあった。`}</p>
    <p>{`ちなみに私のおすすめは`}<a parentName="p" {...{
        "href": "https://amzn.to/2XLArdR",
        "target": "_blank",
        "rel": "nofollow noopener"
      }}>{`テンキーレス茶軸`}</a>{`キーボードである。テンキーレスのため場所を取らず、茶軸と言われる種類のキーは打鍵感が良く音がそれなりに静かで気持ちが良いからである。`}</p>
    <p>{`キーボードについて詳しく知りたい人は下記の記事も参考にしよう。`}</p>
    <Card {...props} title="キーボードの選び方" description="パンタグラフ、メカニカルスイッチなど、キーボードの種類やその打ち心地を解説。" image="ckeyboard" path="/keyboard/" mdxType="Card" />
    <h2 {...{
      "id": "マウスはDPIとボタン数に注目",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%83%9E%E3%82%A6%E3%82%B9%E3%81%AFDPI%E3%81%A8%E3%83%9C%E3%82%BF%E3%83%B3%E6%95%B0%E3%81%AB%E6%B3%A8%E7%9B%AE",
        "aria-label": "マウスはDPIとボタン数に注目 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`マウスはDPIとボタン数に注目`}</h2>
    <p>{`アクションシューティングゲームなどの移動の多いゲームでは、マウスを如何に瞬時に動かせるかが重要になることがある。`}</p>
    <p>{`マウスの読み取り精度として`}<em parentName="p">{`DPI(Dots Per Inch)`}</em>{`という１インチ(2.54cm)移動したときに、何ドット移動するかという指標がある。このDPIの最大値が高く、ボタン一つで切り替えられるようなタイプがゲーミングマウスとして販売されている。`}</p>
    <p>{`また、多くのコマンドがあるゲームではマウスに複数のボタンがついているとショートカット呼び出しができて便利である。`}</p>
    <p>{`どのマウスが適しているかはゲームによりけりであり、自分に合うか握ってみないと分からないところが多いので何とも言えないので一先ずは`}<strong parentName="p"><em parentName="strong"><a parentName="em" {...{
            "href": "https://amzn.to/3HmsCPO",
            "target": "_blank",
            "rel": "nofollow noopener"
          }}>{`G203`}</a>{`などの安いゲーミングマウスから入ってあたりをつけると良い`}</em></strong>{`だろう`}</p>
    <h2 {...{
      "id": "〇〇推奨モデル",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%80%87%E3%80%87%E6%8E%A8%E5%A5%A8%E3%83%A2%E3%83%87%E3%83%AB",
        "aria-label": "〇〇推奨モデル permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`〇〇推奨モデル`}</h2>
    <p>{`ファイナルファンタジーXIV推奨パソコン、モンスターハンター推奨パソコンなど、特定のゲームを快適に遊ぶためのパソコンが推奨パソコンとして売られている。`}</p>
    <p>{`ゲーミングパソコンで一つのゲームだけを遊ぶわけでは大抵はないはずなのであまり意識することはないが、推奨モデルのパソコンを購入すれば、そのゲームでの動作が検証されているため安心ではある。`}</p>
    <p>{`また`}<strong parentName="p">{`ゲームのアイテム特典などが購入時に付くこともあるので気になる場合はチェックするとお得`}</strong>{`なこともある。`}</p>
    <h2 {...{
      "id": "ゲーミングPCに必要なスペックまとめ",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%82%B2%E3%83%BC%E3%83%9F%E3%83%B3%E3%82%B0PC%E3%81%AB%E5%BF%85%E8%A6%81%E3%81%AA%E3%82%B9%E3%83%9A%E3%83%83%E3%82%AF%E3%81%BE%E3%81%A8%E3%82%81",
        "aria-label": "ゲーミングPCに必要なスペックまとめ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ゲーミングPCに必要なスペックまとめ`}</h2>
    <p>{`それでは最後にゲーミングPCにおすすめスペックをまとめておく。`}</p>
    <AccentBox title="ゲーミングPC初心者におすすめのスペック" mdxType="AccentBox">
  <li><span className="bold">GPU</span>・・・<Link to="/gpu/" mdxType="Link">GPU性能比較表</Link>でFHD 60FPSを達成できるものがボトム、レイトレ対応ならRTX 3060以上がおすすめ。</li>
  <li><span className="bold">CPU</span>・・・<Link to="/cpu/" mdxType="Link">CPU性能比較表</Link>からマルチコアとシングルコアの値がバランスよく高いものを選択。高FPSを目指さないならばコスパ重視で。</li>
  <li><span className="bold">モニター</span>・・・サイズはFHDなら特にこだわりなし、QHD画質を目指すなら27インチ、4Kなら40インチ以上。高FPSを目指すなら<a href="https://amzn.to/3zrarBy" target="_blank" rel="nofollow noopenner">G-Sync + 144FPS以上対応のゲーミングモニタ。</a></li>
  <li><span className="bold">メモリ</span>・・・16GB</li>
  <li><span className="bold">ストレージ</span>・・・最低SSD512GB、多くのゲームをインストールしたいなら1TB欲しい。NVMe SSDがより高速。</li>
  <li><span className="bold">キーボード</span>・・・<a href="https://amzn.to/3EzdfQV" target="_blank" rel="nofollow noopenner">Nキーロールオーバーキーボード</a></li>
  <li><span className="bold">マウス</span>・・・<a href="https://amzn.to/3HmsCPO" target="_blank" rel="nofollow noopenner">Logicool G203</a></li>
    </AccentBox>
    <p>{`ゲーミングパソコンを扱うメーカーは複数あるが、初心者向けから上級者向けのサイトまで下記の３つのメーカーをおすすめしたい。`}</p>
    <AccentBox title="おすすめのショップ" mdxType="AccentBox">
  <li><span className="bold"><a href="//ck.jp.ap.valuecommerce.com/servlet/referral?sid=2428064&pid=887394567&vc_url=https%3A%2F%2Fwww.mouse-jp.co.jp%2Fstore%2Fbrand%2Fnextgear%2F" target="_blank" rel="nofollow noopener"><img src="//ad.jp.ap.valuecommerce.com/servlet/gifbanner?sid=2428064&pid=887394567" height="1" width="0" border="0" alt="" />NEXTGEAR</a></span>・・・初めてのゲーミングPCとしては安心のマウスコンピューターのゲーミングブランド。初期価格を抑えてゲームをしたい人向けだが正直十分なスペックがある。</li>
  <li><span className="bold"><a href="https://click.linksynergy.com/fs-bin/click?id=xsv0FDnJs1M&offerid=83593.10000209&type=3&subid=0" target="_blank" rel="nofollow noopener">パソコン工房</a><img border="0" width="1" alt="" height="1" src="https://ad.linksynergy.com/fs-bin/show?id=xsv0FDnJs1M&bids=83593.10000209&type=3&subid=0" /></span>・・・老舗のPCショップ。リーゾナブルで抜群のスペックを誇る。</li>
  <li><span className="bold"><a href="//ck.jp.ap.valuecommerce.com/servlet/referral?sid=2428064&pid=886645278" target="_blank" rel="nofollow noopener">SEVEN</a><img src="//ad.jp.ap.valuecommerce.com/servlet/gifbanner?sid=2428064&pid=886645278" height="1" width="1" alt="" /></span>・・・アキバの独立系。カスタマイズ性に富んだパソコン、ハイスペックパソコンが特に狙い目である。</li>
    </AccentBox>
    <p>{`基本的にはどれも必要以上なスペックのPCを販売しているので、高FPSや解像度を極限まで高めたいなどのコアな要求が無ければNEXTGEARでの購入が良いと思われる。`}</p>
    <p>{`ノートの場合は`}<Link to="/gaming-note/" mdxType="Link">{`ゲーミングノートのおすすめメーカーランキング`}</Link>{`等の記事も参考にして欲しい。それでは快適なゲーミング生活を！`}</p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      